<template>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-subtitle class="orange--text">
                <v-tooltip :disabled="!appointment.comments" top>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                            {{ appointment.subject }}
                        </span>
                    </template>
                    {{ appointment.comments }}
                </v-tooltip>
            </v-list-item-subtitle>

            <v-list-item-subtitle>
                <small>
                    Rendez-vous à réaliser entre le {{ appointment.minDate | toDate() }} et {{ appointment.maxDate | toDate() }}
                </small>
            </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            <CreateAppointmentInstanceDialog :appointment="appointment" @created="$emit('reload')">
                <template v-slot:activator="{ on: dialog }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{ ...dialog, ...tooltip }">
                                <v-icon> mdi-calendar-plus </v-icon>
                            </v-btn>
                        </template>
                        Planifier le rendez-vous parmi les disponibilités proposées
                    </v-tooltip>
                </template>
            </CreateAppointmentInstanceDialog>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import CreateAppointmentInstanceDialog from './CreateAppointmentInstanceDialog.vue';

export default {
    name: 'AppointmentListItemToPlan',

    props: {
        appointment: { type: Object, required: true }
    },

    components: {
        CreateAppointmentInstanceDialog
    }
};
</script>