<template>
    <div>
        <v-card elevation="8">
            <v-carousel
                :show-arrows="getMedia().length > 1"
                show-arrows-on-hover
                cycle
                height="300px"
            >
                <v-carousel-item
                    v-for="item of getMedia()"
                    :key="item.id"
                    :src="getMediaUrl(item.id)"
                />
            </v-carousel>

            <v-sheet color="grey lighten-3" class="px-5 pt-3">
                <v-row>
                    <v-col cols="7" class="pb-0">
                        <v-chip color="white" label>
                            {{ selectedSale.product.reference }} -
                            {{ selectedSale.product.typeLabel }}
                            {{ selectedSale.product.roomsLabel }}
                        </v-chip>

                        <br />

                        <v-chip
                            v-for="product of otherProducts"
                            :key="product.id"
                            class="mr-1"
                            label
                            x-small
                        >
                            {{ product.reference }} -
                            {{ product.typeLabel }}
                            {{ product.roomsLabel }}
                        </v-chip>

                        <br />
                    </v-col>

                    <v-col
                        v-if="getConfig('dashboard.display_program_delivery_date')"
                        class="text-right pb-0"
                    >
                        <span class="caption"> Livraison prévisionnelle </span>
                        <template v-if="selectedSale.plannedDeliveryDate">
                            {{ selectedSale.plannedDeliveryDate | toMonthYearDate() }}
                        </template>
                        <template v-else>
                            <span class="font-italic"> Inconnue </span>
                        </template>
                    </v-col>

                    <v-col cols="12" class="pt-0">
                        <v-card-text
                            class="font-weight-bold pb-0 pl-0"
                            style="padding-left: 12px !important; "
                        >
                            {{ selectedSale.program.title }}
                        </v-card-text>
                        <!-- <br> -->
                        <v-card-text class="pt-0 pl-0" style="padding-left: 12px !important; ">
                            {{ getAddr() }}
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-card-title class="subtitle-1 pa-0 pt-4 mx-4">
                <v-icon color="app-blue" class="mr-2"> mdi-account-outline </v-icon>
                <span class="grey--text text--darken-2"> Mes interlocuteurs </span>

                <v-spacer />

                <v-chip
                    to="/messages"
                    color="app-blue"
                    label
                    dark
                    small
                    v-if="
                        getConfig('messages.userTypes', []).includes(
                            $store.state.application.user.type
                        )
                    "
                >
                    <v-icon class="mr-2"> mdi-email-fast-outline </v-icon>
                    Ma messagerie
                </v-chip>
            </v-card-title>

            <v-card-text>
                <v-list>
                    <v-list-item
                        v-for="(recipient, i) of userMessageRecipients"
                        :key="'recipient-' + i"
                        :to="getConfig('messages.enabled', true) ? `/messages?userId=${recipient.id}` : ''"
                    >
                        <v-list-item-avatar class="app-blue white--text text-uppercase">
                            <v-img
                                v-if="recipient.profilePictureDocumentId"
                                :src="getDocumentUrl(recipient.profilePictureDocumentId)"
                            />
                            <span v-else-if="recipient.name">
                                {{ recipient.name.slice(0, 1) }}
                            </span>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-subtitle>
                                <strong> {{ recipient.firstname }} {{ recipient.name }} </strong>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle>
                                {{ recipient.job }}
                            </v-list-item-subtitle>

                            <v-list-item-subtitle>
                                <small>
                                    {{ recipient.mobile }}
                                </small>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon> mdi-message-text-outline </v-icon>
                                    </v-btn>
                                </template>
                                Envoyer un message
                            </v-tooltip>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>

        <AppointmentsList v-if="getConfig('appointments.enabled')" />
    </div>
</template>

<script>
import messagesMixin from '../../mixins/messages';
import documentsMixin from '../../mixins/documents';

import AppointmentsList from '../appointments/buyer/AppointmentsList.vue';

export default {
    name: 'ProgramSummary',

    mixins: [messagesMixin, documentsMixin],

    components: {
        AppointmentsList
    },

    methods: {
        getMedia() {
            return this.selectedSale.media.filter(
                d => d.category != 'Suivi de chantier' && d.category != 'Portails uniquement'
            );
        },
        getAddr() {
            return (
                this.selectedSale.program.number +
                ' ' +
                this.selectedSale.program.address +
                ' ' +
                (this.selectedSale.program.zip || this.selectedSale.program.city ? ' - ' : ' ') +
                this.selectedSale.program.zip +
                ' ' +
                this.selectedSale.program.city
            );
        }
    },

    computed: {
        otherProducts() {
            return this.selectedSale.products.filter(p => p.id !== this.selectedSale.product.id);
        }
    }
};
</script>
