<template>
    <v-card class="mt-4" elevation="8">
        <v-card-title class="subtitle-1 pa-0 pt-4 mx-4">
            <v-icon color="app-blue" class="mr-2"> mdi-calendar-clock </v-icon>
            <span class="grey--text text--darken-2"> Mes rendez-vous </span>

            <v-spacer />

            <template
                v-if="
                    otherProgramsAppointmentsToPlan.length +
                        otherProgramsAppointmentsPlanned.length >
                        0
                "
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon color="red" v-on="on">fas fa-info-circle</v-icon>
                    </template>
                    Rendez-vous à planifier ou réaliser pour {{ otherProgramsAppointmentsTitle }}
                </v-tooltip>
            </template>
        </v-card-title>

        <v-card-text v-if="selectedSaleAppointments.length > 0">
            <v-list>
                <v-row v-if="toPlan.length > 0" align="center">
                    <v-divider class="mr-2 ml-5" />
                    <small class="grey--text"> Rendez-vous à planifier </small>
                    <v-divider class="ml-2 mr-5" />
                </v-row>

                <AppointmentListItemToPlan
                    v-for="appointment of toPlan"
                    :key="appointment.id"
                    :appointment="appointment"
                    @reload="fetchAppointments()"
                />

                <v-row v-if="planned.length > 0" align="center">
                    <v-divider class="mr-2 ml-5" />
                    <small class="grey--text"> Rendez-vous à venir </small>
                    <v-divider class="ml-2 mr-5" />
                </v-row>

                <AppointmentsListItemPlanned
                    v-for="appointment of planned"
                    :key="appointment.id"
                    :appointment="appointment"
                    @reload="fetchAppointments()"
                />

                <v-row v-if="done.length > 0" align="center">
                    <v-divider class="mr-2 ml-5" />
                    <small class="grey--text"> Rendez-vous passés </small>
                    <v-divider class="ml-2 mr-5" />
                </v-row>

                <AppointmentListItemDone
                    v-for="appointment of doneLimited"
                    :key="appointment.id"
                    :appointment="appointment"
                />

                <v-list-item v-if="!displayMoreDone && done.length > 1" class="no-min-height">
                    <v-list-item-subtitle>
                        <v-btn @click="displayMoreDone = true" x-small depressed>
                            Voir plus...
                        </v-btn>
                    </v-list-item-subtitle>
                </v-list-item>

                <v-list-item v-if="displayMoreDone && done.length > 1" class="no-min-height">
                    <v-list-item-subtitle>
                        <v-btn @click="displayMoreDone = false" x-small depressed>
                            Voir moins...
                        </v-btn>
                    </v-list-item-subtitle>
                </v-list-item>
            </v-list>
        </v-card-text>

        <v-card-text v-if="selectedSaleAppointments.length === 0" class="text-center">
            <h2>
                Aucun rendez-vous 
            </h2>
        </v-card-text>
    </v-card>
</template>

<script>
import { isAfter } from 'date-fns';
import AppointmentListItemDone from './AppointmentListItemDone.vue';
import AppointmentListItemToPlan from './AppointmentListItemToPlan.vue';
import AppointmentsListItemPlanned from './AppointmentsListItemPlanned.vue';

export default {
    name: 'AppointmentsList',

    components: {
        AppointmentListItemDone,
        AppointmentListItemToPlan,
        AppointmentsListItemPlanned
    },

    data: () => ({
        appointments: [],
        displayMoreDone: false
    }),

    computed: {
        selectedSaleAppointments() {
            return this.appointments.filter(a =>
                a.programIds.includes(this.selectedSale.program.id)
            );
        },
        otherSalesAppointments() {
            return this.appointments.filter(
                a => !a.programIds.includes(this.selectedSale.program.id)
            );
        },
        otherProgramsAppointmentsToPlan() {
            return this.otherSalesAppointments.filter(
                a => a.instances.length === 0 || [11, 12].includes(a.instances[0].status)
            );
        },
        otherProgramsAppointmentsPlanned() {
            const now = new Date();
            return this.otherSalesAppointments.filter(
                a =>
                    a.instances.length > 0 &&
                    !isAfter(now, new Date(a.instances[0].endDate)) &&
                    ![11, 12].includes(a.instances[0].status)
            );
        },
        otherProgramsAppointmentsTitle() {
            const programs = [];
            for (const app of this.otherProgramsAppointmentsToPlan) {
                if (!programs.includes(app.programs[0].title)) {
                    programs.push(app.programs[0].title);
                }
            }
            for (const app of this.otherProgramsAppointmentsPlanned) {
                if (!programs.includes(app.programs[0].title)) {
                    programs.push(app.programs[0].title);
                }
            }
            if (programs.length === 0) {
                return '';
            } else if (programs.length === 1) {
                return 'le programme ' + programs[0];
            } else {
                return 'les programmes ' + programs.join(', ');
            }
        },
        toPlan() {
            return this.selectedSaleAppointments.filter(
                a => a.instances.length === 0 || [11, 12].includes(a.instances[0].status)
            );
        },
        planned() {
            const now = new Date();
            return this.selectedSaleAppointments.filter(
                a =>
                    a.instances.length > 0 &&
                    !isAfter(now, new Date(a.instances[0].endDate)) &&
                    ![11, 12].includes(a.instances[0].status)
            );
        },
        done() {
            const now = new Date();
            return this.selectedSaleAppointments.filter(
                a =>
                    a.instances.length > 0 &&
                    isAfter(now, new Date(a.instances[0].endDate)) &&
                    ![11, 12].includes(a.instances[0].status)
            );
        },
        doneLimited() {
            if (this.displayMoreDone) {
                return this.done;
            } else {
                return this.done.slice(0, 1);
            }
        }
    },

    methods: {
        async fetchAppointments() {
            try {
                this.setLoading(true);

                const query = {
                    include: 'instances,intervenants,programs'
                };

                const { appointments, err } = await this.repos.appointments.getBuyersAppointments(
                    query
                );
                if (err) {
                    throw new Error(err);
                }
                this.appointments = appointments;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement des rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    created() {
        this.fetchAppointments();
    }
};
</script>

<style lang="scss" scoped>
.no-min-height {
    min-height: 0;
}
</style>
