<template>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-subtitle class="grey--text">
                <v-tooltip :disabled="!appointment.comments" top>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                            {{ appointment.subject }}
                        </span>
                    </template>
                    {{ appointment.comments }}
                </v-tooltip>
            </v-list-item-subtitle>

            <v-list-item-subtitle>
                <small>
                    Rendez-vous le {{ appointment.instances[0].startDate | toDatetime(false) }}
                    avec {{ appointment.intervenants[0].name }} {{ appointment.intervenants[0].firstname }},
                    durée : {{ appointment.duration | minutesToHours() }}<template v-if="appointment.location">, lieu : {{ appointment.location }}</template>
                </small>
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>

export default {
    name: 'AppointmentListItemDone',

    props: {
        appointment: { type: Object, required: true }
    }
};
</script>